<script setup>

</script>

<template>
  <div class="a">
    <p><i class="bi bi-megaphone-fill"></i>&nbsp;Oracle, it’s time to free JavaScript. <a href="https://javascript.tm">Sign this proposal</a>!</p>
  </div>
</template>

<style scoped>

.a {
  font-size: 0.6em;
  color: yellow;
}
a {
  color: yellow;
}
</style>